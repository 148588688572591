import React from 'react'
import Layout from '../components/Layout'

const About = () => {
  return (
    <Layout>
      <div>
        <h1>About me</h1>
        <p>I am a full-stack developer with 11+ years of experience in Java and JavaScript technologies.</p>
        <p>Apart from programming, I enjoy movies and games.</p>
        <p>I like open source techologies and strong adviser of free software movement.</p>
      </div>
    </Layout>
  );
}
 
export default About